.widget {
    position: relative;
    margin: 0 8px;
    margin-bottom: 16px;
}

.widget:first-child {
    margin-left: 0;
}

.widget:last-child {
    margin-right: 0;
}