.login {
    width: 100%;
    height: 100%;
}

.login-form {
    margin: 120px auto 0;
    max-width: 460px;
    width: calc(100% - 32px);
}

.card {
    background-color: #fff;
    padding: 16px;
    border-radius: 8px;
    /* box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5); */
}

.login-header {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    text-align: center;
}

.admin-text {
    color: #454545;
    font-weight: bold;
}

.card-body {
    display: flex;
    flex-direction: column;
}


.login-button {
    position: relative;
    text-align: center;
    width: 100%;
    height: 44px;
    background-color: #5cc7eb;
    border: 2px solid #5cc7eb;
    border-radius: 4px;
    font-family: sans-serif;
    font-size: 1em;
    color: #fff;
    font-weight: bold;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    cursor: pointer;
    margin-top: 16px;
}

.login-button:hover:not(:disabled) {
    background-color: #fff;
    color: #5cc7eb;
}

.login-button:disabled {
    background-color: #E1E7EB;
    border-color: #E1E7EB;
    cursor: auto;
}

input {
    height: 44px;
    width: 100%;
    border: 1px solid #E1E7EB;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    margin: 8px 0;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
}

input:first-child {
    margin-top: 32px;
}

input:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #5cc7eb;
}

.error {
    margin-top: 16px;
    color: #eb5c5c;
}