.horizontal-loader {
    text-align: center;
}

.horizontal-loader>div {
    width: 14px;
    height: 14px;
    margin: 0 2px;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: bouncedelay 1.4s infinite ease-in-out both;
    animation: bouncedelay 1.4s infinite ease-in-out both;
}

.bounce1 {
    background: #c7eb5c;
    -webkit-animation-delay: -0.48s !important;
    animation-delay: -0.48s !important;
}

.bounce2 {
    background: #5cc7eb;
    -webkit-animation-delay: -0.32s !important;
    animation-delay: -0.32s !important;
}

.bounce3 {
    background: #F4AC3D;
    -webkit-animation-delay: -0.16s !important;
    animation-delay: -0.16s !important;
}

.bounce4 {
    background: #eb5c5c;
}

@-webkit-keyframes bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}