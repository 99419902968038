.user-item-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 8px 0;
    min-height: 48px;
}

.user-item {
    padding: 8px;
    transition: all 0.2s;
    text-decoration: none;
    color: inherit;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    border-radius: 4px;
    cursor: pointer;
    flex: 1;
}

.user-item:first-child {
    margin-top: 0;
}

.user-item:last-child {
    margin-bottom: 0;
}

.verified-button:hover,
.user-item:hover {
    background-color: rgba(153, 153, 153, 0.12);
}

.user-profile {
    display: flex;
    align-items: center;
}

.user-display {
    display: flex;
    flex-direction: column;
}

.user-title {
    font-weight: 500;
    font-size: 15px;
    color: #101010;
}

.user-subheader {
    font-weight: 400;
    font-size: 14px;
    color: #65676B;
    display: flex;
    align-items: center;
}

.verified {
    margin-left: 4px;
}

.verified-button {
    margin-left: 8px;
    height: 36px;
    width: 36px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.verified-button > .verified {
    margin: unset;
}

.creation-date {
    text-align: right;
}

.user-creation-date {
    font-weight: 400;
    font-size: 14px;
    color: #65676B;
}

.status {
    border-radius: 32px;
    font-weight: 400;
    font-size: 14px;
    color: #101010;
    padding: 4px 8px;
}

.user-icon {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 8px;
}