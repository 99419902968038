* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  line-height: 1.4;
  background-color: #f0f2f5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

a, button {
  outline: none;
  border: none;
}

a::-moz-focus-inner, button::-moz-focus-inner {
  border: 0;
}

.icon-button {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
}

.icon-button:hover {
  background-color: rgba(153, 153, 153, 0.12);
}