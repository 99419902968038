.search {
    width: 164px;
    height: 32px;
    border-radius: 32px;
    padding: 8px 16px 8px 16px;
    margin-left: 16px;
}

.search:focus {
    width: 200px;
}

.search-results {
    position: absolute;
    left: 16px;
    top: 68px;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 8px;
    z-index: 10;
    max-width: 400px;
    width: 100%;
    height: 400px;
    overflow-y: scroll;

    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}

.search-results .mobile {
    left: 8px;
    top: 54px;
}