.toolbar,
.toolbar-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0;
    padding: 8px 16px;
    margin: 0 auto;
    background-color: #fff;
    z-index: 10;
}

.toolbar-menu,
.toolbar-actions {
    display: flex;
    align-items: center;
}

.toolbar-actions span {
    color: #101010;
    margin-right: 16px;
}

.toolbar {
    height: 64px;
}

.toolbar-mobile {
    height: 48px;
}

.toolbar-mobile .toolbar-actions span {
    display: none;
}